@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?-nddtoy");
  src:
    url("./icomoon.eot?#iefix-nddtoy") format("embedded-opentype"),
    url("./icomoon.woff?-nddtoy") format("woff"),
    url("./icomoon.ttf?-nddtoy") format("truetype"),
    url("./icomoon.svg?-nddtoy#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-peak-power:before {
  content: "\e98a";
}
.icon-menu-reports:before {
  content: "\e989";
}
.icon-battery:before {
  content: "\e980";
}
.icon-pressure:before {
  content: "\e981";
}
.icon-radon:before {
  content: "\e982";
}
.icon-motion:before {
  content: "\e983";
}
.icon-sound:before {
  content: "\e984";
}
.icon-temperature:before {
  content: "\e985";
}
.icon-humidity:before {
  content: "\e986";
}
.icon-light:before {
  content: "\e987";
}
.icon-air-quality:before {
  content: "\e97f";
}
.icon-energy:before {
  content: "\e97a";
}
.icon-waste:before {
  content: "\e97b";
}
.icon-costs:before {
  content: "\e97c";
}
.icon-water:before {
  content: "\e97d";
}

.icon-voc:before {
  content: "\e988";
}
.icon-co2:before {
  content: "\e97e";
}
.icon-info:before {
  content: "\e979";
}
.icon-ack-hor:before {
  content: "\e977";
}
.icon-ack-vert:before {
  content: "\e978";
}
.icon-star:before {
  content: "\e976";
}
.icon-doc-filled:before {
  content: "\e975";
}
.icon-camera:before {
  content: "\e974";
}
.icon-feedback:before {
  content: "\e973";
}
.icon-livedata:before {
  content: "\e970";
}
.icon-aggregation:before {
  content: "\e971";
}
.icon-measurement:before {
  content: "\e972";
}
.icon-send:before {
  content: "\e96f";
}
.icon-dashboard:before {
  content: "\e96e";
}
.icon-type:before {
  content: "\e96d";
}
.icon-move-down:before {
  content: "\e96b";
}
.icon-move-up:before {
  content: "\e96c";
}
.icon-chart-bar:before {
  content: "\e965";
}
.icon-chart-bubble:before {
  content: "\e966";
}
.icon-chart-column:before {
  content: "\e967";
}
.icon-chart-line-alt:before {
  content: "\e968";
}
.icon-chart-scatter:before {
  content: "\e969";
}
.icon-chart-table:before {
  content: "\e96a";
}
.icon-chart-area:before {
  content: "\e962";
}
.icon-chart-heatmap:before {
  content: "\e963";
}

.icon-chart-line:before {
  content: "\e964";
}

.icon-menu-qsystem:before {
  content: "\e961";
}

.icon-template:before {
  content: "\e960";
}

.icon-emptydoc:before {
  content: "\e95f";
}

.icon-projectpie:before {
  content: "\e95f";
}

.icon-empty:before {
  content: "\e95e";
}

.icon-menu-seasonal:before {
  content: "\e95d";
}

.icon-chart:before {
  content: "\e95c";
}

.icon-tasktype-administrative:before {
  content: "\e958";
}

.icon-tasktype-correcting:before {
  content: "\e959";
}

.icon-tasktype-operations:before {
  content: "\e95a";
}

.icon-tasktype-project:before {
  content: "\e95b";
}

.icon-draghandle:before {
  content: "\e954";
}

.icon-type-boolean:before {
  content: "\e955";
}

.icon-type-numeric:before {
  content: "\e956";
}

.icon-type-text:before {
  content: "\e957";
}

.icon-recurring-task-alt:before {
  content: "\e952";
}

.icon-users:before {
  content: "\e953";
}

.icon-refresh:before {
  content: "\e950";
}

.icon-duplicate:before {
  content: "\e951";
}

.icon-goal:before {
  content: "\e94f";
}

.icon-comment-new:before {
  content: "\e94e";
}

.icon-activity:before {
  content: "\e94d";
}

.icon-email:before {
  content: "\e94c";
}

.icon-user-new:before {
  content: "\e94b";
}

.icon-sensor:before {
  content: "\e94a";
}

.icon-doc:before {
  content: "\e949";
}

.icon-attachment:before {
  content: "\e946";
}

.icon-comments:before {
  content: "\e947";
}

.icon-subtasks:before {
  content: "\e948";
}

.icon-rename:before {
  content: "\e945";
}

.icon-settings:before {
  content: "\e944";
}

.icon-download:before {
  content: "\e943";
}

.icon-bell:before {
  content: "\ea18";
}

.icon-meter:before {
  content: "\ea16";
}

.icon-zoom-in:before {
  content: "\ea17";
}

.icon-sensor-heat:before {
  content: "\e940";
}

.icon-sensor-humidity:before {
  content: "\e940";
}

.icon-sensor-temp:before {
  content: "\e941";
}

.icon-dropbox:before {
  content: "\e93e";
}

.icon-gdrive:before {
  content: "\e93f";
}

.icon-share:before {
  content: "\e939";
}

.icon-history:before {
  content: "\e93a";
}

.icon-folder:before {
  content: "\e93b";
}

.icon-folder-open:before {
  content: "\e93c";
}

.icon-kpi-new:before {
  content: "\e93d";
}

.icon-tasks-list:before {
  content: "\e936";
}

.icon-tasks-kanban:before {
  content: "\e937";
}

.icon-threedots:before {
  content: "\e938";
}

.icon-doc-new:before {
  content: "\e930";
}

.icon-act:before {
  content: "\e931";
}

.icon-bulb:before {
  content: "\e932";
}

.icon-sortdn:before {
  content: "\e933";
}

.icon-sortup:before {
  content: "\e934";
}

.icon-task-new:before {
  content: "\e935";
}

.icon-upload:before {
  content: "\e92e";
}

.icon-folder-new:before {
  content: "\e92f";
}

.icon-arrow-alt2:before {
  content: "\e928";
}

.icon-arrow-left:before {
  content: "\e9f8";
}

.icon-arrow-up:before {
  content: "\e9f8";
  transform: rotate(90deg);
}

.icon-doc-course:before {
  content: "\e929";
}

.icon-doc-doc:before {
  content: "\e92a";
}

.icon-doc-pdf:before {
  content: "\e92b";
}

.icon-doc-xls:before {
  content: "\e92c";
}

.icon-play:before {
  content: "\e92d";
}

.icon-flag:before {
  content: "\e927";
}

.icon-comment:before {
  content: "\e91e";
}

.icon-submit:before {
  content: "\e91f";
}

.icon-layout:before {
  content: "\e920";
}

.icon-weather:before {
  content: "\e921";
}

.icon-eye:before {
  content: "\e922";
}

.icon-eye-off:before {
  content: "\e923";
}

.icon-clock:before {
  content: "\e924";
}

.icon-delete:before {
  content: "\e925";
}

.icon-edit:before {
  content: "\e926";
}

.icon-actions:before {
  content: "\e91d";
}

.icon-columns-visibility:before {
  content: "\e916";
}

.icon-filter:before {
  content: "\e917";
}

.icon-publish:before {
  content: "\e918";
}

.icon-save:before {
  content: "\e919";
}

.icon-save-template:before {
  content: "\e91a";
}

.icon-search:before {
  content: "\e91b";
}

.icon-x:before {
  content: "\e91c";
}

.icon-arrow:before {
  content: "\e900";
}

.icon-arrow-alt:before {
  content: "\e901";
}

.icon-calendar:before {
  content: "\e902";
}

.icon-checkmark:before {
  content: "\e903";
}

.icon-help:before {
  content: "\e904";
}

.icon-help-full:before {
  content: "\e905";
}

.icon-menu-assets:before {
  content: "\e906";
}

.icon-menu-communication:before {
  content: "\e907";
}

.icon-menu-dashboard:before {
  content: "\e908";
}

.icon-menu-docs:before {
  content: "\e909";
}

.icon-menu-energy-monitoring:before {
  content: "\e90a";
}

.icon-menu-environment:before {
  content: "\e9b7";
}

.icon-menu-energy-use:before {
  content: "\e90b";
}

.icon-menu-incidents:before {
  content: "\e90c";
}

.icon-menu-iso:before {
  content: "\e90d";
}

.icon-menu-oneonone:before {
  content: "\e90e";
}

.icon-menu-projects:before {
  content: "\e90f";
}

.icon-menu-tasks:before {
  content: "\e910";
}

.icon-menu-team:before {
  content: "\e911";
}

.icon-menu-virtual-classroom:before {
  content: "\e912";
}

.icon-minus:before {
  content: "\e913";
}

.icon-notifications:before {
  content: "\e914";
}

.icon-plus:before {
  content: "\e915";
}

.icon-arrow-down:before {
  content: "\ea14";
  opacity: 0.5;
  font-size: 10px;
}

.icon-arrow-up:before {
  content: "\ea15";
  opacity: 0.5;
  font-size: 10px;
}

.icon-alarm:before {
  content: "\e90c";
}

.icon-menu-iaq:before {
  content: "\e97f";
}

.icon-energyconsumption:before {
  content: "\e97a";
}

.icon-energyeffect:before {
  content: "\e98a";
}
.icon-cone:before {
  content: "\e98b";
}

//Asset types icon
.icon-organization2:before {
  content: "\e998";
}
.icon-organization:before {
  content: "\e999";
}
.icon-folder2:before {
  content: "\e99b";
}
.icon-building:before {
  content: "\e99c";
}
.icon-apartment:before {
  content: "\e99d";
}
.icon-business:before {
  content: "\e99e";
}
.icon-factory:before {
  content: "\e99f";
}
.icon-city:before {
  content: "\e9a0";
}
.icon-globe:before {
  content: "\e99a";
}
.icon-home:before {
  content: "\e9a1";
}
.icon-home-work:before {
  content: "\e9a4";
}
.icon-store:before {
  content: "\e9a5";
}
.icon-transportation:before {
  content: "\e9a6";
}
.icon-home-water:before {
  content: "\e9a2";
}
.icon-waterhome:before {
  content: "\e9a3";
}
.icon-transportation:before {
  content: "\e9a6";
}
.icon-kindergarden:before {
  content: "\e9a7";
}
.icon-nursery:before {
  content: "\e9a9";
}
.icon-pump:before {
  content: "\e9aa";
}
.icon-streetlights:before {
  content: "\e9ab";
}
.icon-culture:before {
  content: "\e9ac";
}
.icon-trafficlight:before {
  content: "\e9ad";
}
.icon-technical:before {
  content: "\e9d4";
}
.icon-energycentral:before {
  content: "\e9af";
}
.icon-administration:before {
  content: "\e9b0";
}
.icon-firehouse:before {
  content: "\e9b1";
}
.icon-healtinstitution:before {
  content: "\e9b2";
}
.icon-hotel:before {
  content: "\e9b3";
}
.icon-watertreatments:before {
  content: "\e9b4";
}
.icon-qrcode:before {
  content: "\e9f7";
}
.icon-lock:before {
  content: "\ea12";
}
.icon-unlock:before {
  content: "\ea13";
}

// Meter icons
.icon-tag-energymeter:before,
.icon-tag-energydeviation:before,
.icon-tag-power:before {
  content: "\e98c";
}
.icon-tag-waste:before {
  content: "\e97b";
}
.icon-tag-temperature:before {
  content: "\e997";
}
.icon-tag-watermeter:before {
  content: "\e97d";
}
.icon-tag-gasmeter:before {
  content: "\e994";
}
.icon-tag-area:before {
  content: "\e98f";
}
.icon-tag-battery:before {
  content: "\e98d";
}
.icon-tag-binary:before {
  content: "\e98e";
}
.icon-tag-co2:before,
.icon-tag-co2factor:before {
  content: "\e97e";
}
.icon-tag-cop:before {
  content: "\e990";
}
.icon-tag-costfactor:before {
  content: "\e991";
}
.icon-tag-fixedcost:before {
  content: "\e991";
}
.icon-tag-countermeter:before {
  content: "\e992";
}
.icon-tag-discrete:before {
  content: "\e993";
}
.icon-tag-humidity:before {
  content: "\e986";
}
.icon-tag-light:before {
  content: "\e987";
}
.icon-tag-motion:before {
  content: "\e983";
}
.icon-tag-multisensor:before {
  content: "\e995";
}
.icon-tag-pressure:before {
  content: "\e981";
}
.icon-tag-doorsensor:before {
  content: "\e9c2";
}

.icon-tenant:before {
  content: "\e9c3";
}

.icon-tag-radon:before {
  content: "\e982";
}
.icon-tag-sound:before {
  content: "\e984";
}
.icon-tag-voc:before {
  content: "\e988";
}
.icon-tag-weight:before {
  content: "\e996";
}
.icon-tag-countersensor:before {
  content: "\e9c2";
}

.icon-tag-genericmeter:before {
  content: "\e94a";
}

.icon-ec_solar:before {
  content: "\e9ae";
}
.icon-ec_solarstring:before {
  content: "\e9e5";
}
.icon-ec_solarinverter:before {
  content: "\e9f9";
}
.icon-ec_grid:before {
  content: "\e9b5";
}
.icon-ec_wind:before {
  content: "\e9b6";
}
.icon-ec_districtheating:before {
  content: "\ec";
}
.icon-ec_oil:before {
  content: "\e9b8";
}
.icon-ec_gas:before {
  content: "\e9b9";
}
.icon-ec_battery:before {
  content: "\e9ba";
}

.icon-ec_biooil:before {
  content: "\e9bd";
}
.icon-ec_districtheating1:before {
  content: "\e9be";
}
.icon-ec_electricitycoal:before {
  content: "\e9c0";
}
.icon-ec_electricitygas:before {
  content: "\e9c1";
}
.icon-ec_electricitygrid:before {
  content: "\e9c4";
}
.icon-ec_electricityhydro:before {
  content: "\e9c5";
}
.icon-ec_electricitysolar:before {
  content: "\e9c6";
}
.icon-ec_electricitywind:before {
  content: "\e9c7";
}
.icon-ec_gas1:before {
  content: "\e9c8";
}
.icon-ec_heatpumpground:before {
  content: "\e9c9";
}
.icon-ec_heatpumpwater:before {
  content: "\e9ca";
}
.icon-ec_oil1:before {
  content: "\e9cb";
}
.icon-ec_solar1:before {
  content: "\e9cc";
}
.icon-ec_biomasscoal:before {
  content: "\e9d6";
}
.icon-ec_biomasspelets:before {
  content: "\e9d7";
}
.icon-ec_districcooling:before {
  content: "\e9d8";
}

.icon-ca_heating:before {
  content: "\e9d5";
}
.icon-ca_cooling:before {
  content: "\e9d3";
}
.icon-ca_ventilation:before {
  content: "\e9d1";
}
.icon-ca_pump:before {
  content: "\e9cf";
}
.icon-ca_carcharger:before {
  content: "\e9bf";
}
.icon-ca_freezer:before {
  content: "\e9d2";
}
.icon-ca_lights:before {
  content: "\e9ce";
}
.icon-ca_fan:before {
  content: "\e9d0";
}
.icon-ca_snowmelt:before {
  content: "\e9cd";
}
.icon-ca_heatingair:before {
  content: "\e9bb";
}
.icon-ca_heatingwaters:before {
  content: "\e9bc";
}

.icon-catering:before {
  content: "\e9f6";
}
.icon-restaurant:before {
  content: "\e9d9";
}
.icon-gallery:before {
  content: "\e9da";
}
.icon-cinema:before {
  content: "\e9db";
}
.icon-zoo:before {
  content: "\e9dc";
}
.icon-museum:before {
  content: "\e9dd";
}
.icon-concerthall:before {
  content: "\e9de";
}
.icon-library:before {
  content: "\e9df";
}
.icon-school2:before {
  content: "\e9e0";
}
.icon-lab1:before {
  content: "\e9e2";
}
.icon-lab2:before {
  content: "\e9e1";
}
.icon-police:before {
  content: "\e9e3";
}
.icon-aquaculture:before {
  content: "\e9e4";
}
.icon-barn:before {
  content: "\e9fa";
}
.icon-greenhouse:before {
  content: "\e9e6";
}
.icon-powerplant:before {
  content: "\e9e7";
}
.icon-workshop:before {
  content: "\e9e8";
}
.icon-swimminghall:before {
  content: "\e9e9";
}
.icon-stadium:before {
  content: "\e9ea";
}
.icon-sports:before {
  content: "\e9eb";
}
.icon-gym:before {
  content: "\e9ec";
}
.icon-icehall:before {
  content: "\e9ed";
}
.icon-parking:before {
  content: "\e9ee";
}
.icon-hangar:before {
  content: "\e9ef";
}
.icon-port:before {
  content: "\e9f0";
}
.icon-traintstation:before {
  content: "\e9f1";
}
.icon-coldstorage:before {
  content: "\e9f2";
}
.icon-warehouse:before {
  content: "\e9f3";
}
.icon-factory2:before {
  content: "\e9f4";
}
.icon-church:before {
  content: "\e9f5";
}

.icon-energycentral:before {
  content: "\e9fb";
}

.icon-sub_gas:before {
  content: "\e9b9";
}

.icon-sub_co2:before {
  content: "\e97e";
}

.icon-externally_in:before {
  content: "\e9fd";
}

.icon-externally_out:before {
  content: "\e9fc";
}

.icon-internally_produced:before {
  content: "\ea00";
}

.icon-imported:before {
  content: "\e9fe";
}

.icon-exported:before {
  content: "\e9ff";
}

.icon-hospital:before {
  content: "\ea01";
}

.icon-educational-building:before {
  content: "\ea02";
}

.icon-warehouse2:before {
  content: "\ea03";
}

.icon-kitchen1:before {
  content: "\ea04";
}

.icon-kitchen2:before {
  content: "\ea05";
}

.icon-office-building:before {
  content: "\ea06";
}

.icon-sport1:before {
  content: "\ea07";
}

.icon-sport2:before {
  content: "\ea08";
}

.icon-housing:before {
  content: "\ea09";
}

.icon-barrack:before {
  content: "\ea0a";
}

.icon-hangar1:before {
  content: "\ea0b";
}

.icon-garage:before {
  content: "\ea0c";
}

.icon-garrison:before {
  content: "\ea0d";
}

.icon-multi-use-hall:before {
  content: "\ea0e";
}

.icon-circle:before {
  content: "\ea0f";
}