@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FamiljenGrotesk';
  src: url('./assets/fonts/FamiljenGrotesk-Regular.ttf') format('truetype');
}

body {
  background-color: #fff !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.FamiljenGrotesk {
  font-family: 'FamiljenGrotesk';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}
