@import "./assets//webfonts/icons.css";

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

ul{
	list-style: initial;
	padding: 20px 40px;
}

.dark .organizationLogo {
	filter: invert(1) brightness(1.85) contrast(0.95) saturate(0);
}

.remoteControl {
	width: 15vw;
	height: 15vw;
	background: var(--RCbg);
	border-radius: 50%;
	padding: 20px;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	transform: rotate(45deg);
	box-shadow: inset -12px 0 12px -6px #322849,
		inset 12px 0 12px -6px #4c3b5e,
		inset 0 0 0 12px #1A1F35,
		inset 2px 0 4px 12px rgba(0, 0, 0, 0.4),
		1px 0 14px rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	position: relative;
	margin: 10px auto;
}

.remoteControl a {
	text-decoration: none;
}

.remoteControl .center-button {
	display: block;
	height: 38%;
	width: 38%;
	position: absolute;
	top: 31%;
	left: 31%;
	background: #1A1F35;
	border-radius: 50%;
	box-shadow: 1px 0 4px rgba(0, 0, 0, 0.8);
}

.remoteControl .button {
	display: block;
	width: 48%;
	height: 48%;
	margin: 1%;
	position: relative;
	float: left;
	box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.4), inset 0 0 0 1px #000;
}

:root{
	--RCbg: #14151f;
}

.remoteControl .button::after {
	content: "";
	display: block;
	width: 50%;
	height: 50%;
	background: var(--RCbg);
	position: absolute;
	border-radius: inherit;
}

.remoteControl .button.top {
	border-radius: 100% 0 0 0;
	background: #1A1F35;
}

.remoteControl .button.top::after {
	bottom: 0;
	right: 0;
	box-shadow: inset 2px 1px 2px 0 rgba(0, 0, 0, 0.4), 10px 10px 0 10px var(--RCbg);
	-webkit-transform: skew(-3deg, -3deg) scale(0.96);
	-moz-transform: skew(-3deg, -3deg) scale(0.96);
	transform: skew(-3deg, -3deg) scale(0.96);
}

.remoteControl .button.right {
	border-radius: 0 100% 0 0;
	background: #1A1F35;
}

.remoteControl .button.right::after {
	bottom: 0;
	left: 0;
	box-shadow: inset -2px 3px 2px -2px rgba(0, 0, 0, 0.4), -10px 10px 0 10px var(--RCbg);
	-webkit-transform: skew(3deg, 3deg) scale(0.96);
	-moz-transform: skew(3deg, 3deg) scale(0.96);
	transform: skew(3deg, 3deg) scale(0.96);
}

.remoteControl .button.left {
	border-radius: 0 0 0 100%;
	background: #1A1F35;
}

.remoteControl .button.left::after {
	top: 0;
	right: 0;
	box-shadow: inset 2px -1px 2px 0 rgba(0, 0, 0, 0.4), 10px -10px 0 10px var(--RCbg);
	-webkit-transform: skew(3deg, 3deg) scale(0.96);
	-moz-transform: skew(3deg, 3deg) scale(0.96);
	transform: skew(3deg, 3deg) scale(0.96);
}

.remoteControl .button.bottom {
	border-radius: 0 0 100% 0;
	background: #1A1F35;
}

.remoteControl .button.bottom::after {
	top: 0;
	left: 0;
	box-shadow: inset -2px -3px 2px -2px rgba(0, 0, 0, 0.4), -10px -10px 0 10px var(--RCbg);
	-webkit-transform: skew(-3deg, -3deg) scale(0.96);
	-moz-transform: skew(-3deg, -3deg) scale(0.96);
	transform: skew(-3deg, -3deg) scale(0.96);
}

.remoteControl i {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: absolute;
	font-size: 42px;
	top: 36%;
	left: 39%;
	color: white
}

.remoteControl .top i {
	top: 24%;
	left: 25%;
}

.remoteControl .left i {
	top: 28%;
	left: 25%;
}

.remoteControl .right i {
	top: 25%;
	left: 28%;
}

.remoteControl .bottom i {
	top: 27%;
	left: 30%;
}

.remoteControl .center-button i {
	top: 25%;
	left: 28%;
}

.remoteControl img{
	width: 3vw;
}

.remoteControl .button.top:hover,
.remoteControl .button.bottom:hover,
.remoteControl .button.left:hover,
.remoteControl .button.right:hover,
.remoteControl .center-button:hover {
	background: #8c1697;
	cursor: pointer;
}

.h-8vh {
	height: 8vh;
}

.h-8vh img {
	height: 5vh;
}

.tag__header{
	background: #323545;
	-height: 80px;
	width: 100%;
	padding: 12px 25px;
}

.tag__header img{
	height: 50px;
}

.tag__body{
	background: #FAFBFF;
	color: #323745;
}

.tag__body--header{
	background: #fff;
	margin-bottom: 20px;
	padding: 30px 25px;
}

.tag__body--header h2{
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: -0.02em;
	margin-bottom: 6px;
}

.tag__body--header h3{
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #868A9E;
	margin-bottom: 16px;
}

.tag__body--header h4{
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #323745;
}

.tag__charts {
	background: #fff;
	padding: 18px 25px;
}

.tag__card {
	background: #fff	;
	border: 1px solid #DBDCE0;
	border-radius: 5px;
	padding: 20px 15px;
	margin-bottom: 20px
}

.tag__card--header-top{
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #DBDCE0;
}
.tag__card--header-top div{
	display: flex;
}
.tag__card--header-top img{
	height: 30px;
	margin-right: 10px;
}

.tag__card--header-top h3{
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: -0.02em;
	color: #323745;
	margin-top: 5px;
}

.tag__card--header-top h2{
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	text-align: right;
	float: right;
	color: #323745;
	justify-content: flex-end;
}

.tag__card--header-top h2 b{
	font-size: 18px;
	font-weight: 500;
}

.tag__card--header-bottom{
	display: flex;
	justify-content: space-between;
}

.tag__body span{
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #868A9E;
	margin-top: 8px;
}

.tag__card--expander{
	padding: 3px 4px;
	border: solid 1px #9a9daf;
	border-radius: 4px;
}

.tag__card--expander span{
	font-size: 16px;
	filter:grayscale(1) brightness(1.4);
}

.tag__charts--chart{
	width: 100%;
	height: 300px;
	margin-top: 15px;
}

.loader-wrapper{
	padding: 150px 0;
	background: white;
}


.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgb(50 53 69 / 20%);
	border-right: 1.1em solid rgb(50 53 69 / 20%);
	border-bottom: 1.1em solid rgb(50 53 69 / 20%);
	border-left: 1.1em solid rgb(50 53 69 / 100%);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}



.progress {
	width: 200px;
	height: 200px;
	font-size: 30px;
	color: #fff;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	background: #07070c;
	text-align: center;
	line-height: 200px;
	margin: 20px
}

.progress::after {
	content: "%";
}

.progress .title {
	position: relative;
	z-index: 100;
}

.progress .overlay {
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: #07070c
}

.progress .left,
.progress .right {
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border: 10px solid #222235;
	border-radius: 100px 0px 0px 100px;
	border-right: 0;
	transform-origin: right;
}

.progress .left {
	animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
	animation: load2 .5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
	animation: load3 .8s linear forwards 1s;
}

@keyframes load1 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(180deg);
	}
}

@keyframes load2 {
	0% {
		z-index: 100;
		transform: rotate(180deg);
	}

	100% {
		z-index: 100;
		transform: rotate(270deg);
	}
}

@keyframes load3 {
	0% {
		z-index: 100;
		transform: rotate(180deg);
	}

	100% {
		z-index: 100;
		transform: rotate(315deg);
	}
}



.progress-card {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	-margin: 0 20px;
	-width: 20%;
	-height: 350px;
	height: 50%;
	border-radius: 5px;
	-border: solid 1px red;

	transform: scale(1.15);
}

.progress-card .percent {
	position: relative;
}

.progress-card svg {
	position: relative;
	width: 240px;
	height: 240px;
	transform: rotate(-90deg);
	transform: rotate(-212deg);
}

.progress-card svg circle {
	width: 100%;
	height: 100%;
	fill: none;
	stroke: #D5D9DF;
	stroke-width: 8;
	stroke-linecap: round;
	stroke-dasharray: 690px;
	stroke-dashoffset: calc(690px - (690px * 68) / 100);
}

.dark .progress-card svg circle{
	stroke: #45494F;
}

.progress-card svg circle:last-of-type {
	stroke-dasharray: 690px;
	stroke-dashoffset: calc(690px - (690px * var(--percent)) / 100);
	stroke: #3498db;
}

.progress-card .number {
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -30%);
	width: 90%;
	display: flex;
	text-align: center;
	justify-content: center;
	flex-direction: column;
}

.progress-card .number img {
	-margin-top: 12vw;
}

.progress-card .number h2 {
	font-weight: 500;
	font-size: 1.3rem;
	font-size: 1vw;
	line-height: 1.3vw;
	letter-spacing: .1rem;
	margin: .7rem 0 0 0;
	margin: 1vw 0 0 0;
}

.progress-card .number h3 {
	font-weight: 600;
	font-size: 2.2rem;
	font-size: 2.6vw;
	line-height: 1.9vw;
	margin: .5rem 0;
	margin: 2vh 0 0 0;
}

.progress-card .number h3 span {
	font-size: 1rem;
	font-size: .9vw;
	margin-left: .2vw;
}

.progress-card .title h2 {
	font-weight: 500;
	font-size: 1.4rem;
	font-size: 1.2vw;
	color: #999;
	letter-spacing: 4px;
	margin: 1.4vw 0 3rem 0;
	margin: 0 0 4vw 0;
	text-transform: uppercase;
}

.effektprogress .progress-card svg{
	width: 210px;
	height: 210px;
	transform: rotate(-224deg);
}

.effektprogress .progress-card svg circle {
	stroke-width: 10;
	stroke-dasharray: 625px;
	stroke-dashoffset: calc(625px - (625px * 75) / 100);
}

.effektprogress .progress-card svg circle:last-of-type {
	stroke-dasharray: 625px;
	stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
}


.title--grid{
	margin-top: 2vh;
}

.progress-card:nth-child(1) svg circle:last-of-type {
	stroke: #B2E484;
	stroke: url(#gradient-1);
}

.progress-card:nth-child(2) svg circle:last-of-type {
	stroke: #2ecc71;
	stroke: url(#gradient-1);
}

.flow-animation{
	width: 30%;
	margin: 0 5%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	padding-top: 9vh
}

.flow-animation .gray-dots{
	stroke: #ddd;
}

.flow-animation .green-dots{
	stroke: #5D9A79;
}

.dark .flow-animation .green-dots{
	stroke: #12eba2;
	stroke: #cfc;
}

.dark .flow-animation .gray-dots{
	stroke: #616161;
}

.flow-animation svg{
	margin-top: -10%;
	width: 100%;
	height: auto;
}

.progress-card-1-wrapper{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.flow__1 {
	animation: f-animation 2s infinite;
	animation-timing-function: linear;
	clip-path: inset(-25% 100% 0% -20%);
}

.flow__2 {
	animation: f-animation2 2s infinite;
	animation-timing-function: linear;
	clip-path: inset(-25% 100% 0% -20%);
}

.flow__2--nosolar{
	animation: f-animation2-nosolar 2s infinite;
}

.flow__3 {
	animation: f-animation3 2s infinite;
	animation-timing-function: linear;
	clip-path: inset(-25% 100% 0% -20%);
}

.flow__3--solartogrid {
	animation: f-animation3-solar-to-grid 2s infinite;
}

.flow__4 {
	animation: f-animation4 2s infinite;
	animation-timing-function: linear;
	clip-path: inset(-25% 100% 0% -20%);
}

.flow__5--batterytogrid {
	animation: f-animation5-battery-to-grid 2s infinite;
}

.flow__5--gridtobattery {
	animation: f-animation5-grid-to-battery 2s infinite;
}

@keyframes f-animation {
	0% {
		clip-path: circle(180px at 0 0px);
	}
	50% {
		clip-path: circle(180px at 0 0px);
	}
	80% {
		clip-path: circle(0px at 0 0px);
	}
	100% {
		clip-path: circle(0px at 0 0px);
	}
}

@keyframes f-animation2 {
	0% {
		clip-path: circle(180px at 0 100px);
	}
	50%{
		clip-path: circle(0px at 0 100px);
	}
	100% {
		clip-path: circle(0px at 0 100px);
	}
}

@keyframes f-animation2-nosolar {
	0% {
		clip-path: circle(280px at 0 100px);
	}
	100% {
		clip-path: circle(280px at 0 100px);
	}
}

@keyframes f-animation3 {
	0% {
		clip-path: circle(180px at 0 0px);
	}
	50%{
		clip-path: circle(0px at 0 0px);
	}
	100% {
		clip-path: circle(0px at 0 0px);
	}
}

@keyframes f-animation3-solar-to-grid {
	0% {
		clip-path: circle(180px at 160px 120px);
	}
	50%{
		clip-path: circle(180px at 160px 120px);
	}
	100% {
		clip-path: circle(0px at 160px 120px);
	}
}

@keyframes f-animation3-no-grid {
	0% {
		clip-path: circle(200px at 0 0px);
	}
	100% {
		clip-path: circle(200px at 0 0px);
	}
}

@keyframes f-animation4 {
	0% {
		clip-path: circle(200px at 0 0px);
	}

	50% {
		clip-path: circle(200px at 0 0px);
	}

	80% {
		clip-path: circle(0px at 0 0px);
	}

	100% {
		clip-path: circle(0px at 0 0px);
	}
}

@keyframes f-animation5-battery-to-grid {
	0% { clip-path: xywh(0 0 100% 100%) }
	65% { clip-path: xywh(0 0 100% 0) }
	100% { clip-path: xywh(0 0 100% 0) }
}

@keyframes f-animation5-grid-to-battery {
	0% { clip-path: xywh(0 0 100% 100%) }
	50% { clip-path: xywh(0 0 100% 100%) }
	100% { clip-path: xywh(0 100% 100% 100%) }
}

.lineanimation {
	-animation: lineanimation2 var(--duration) infinite;
	-animation-timing-function: ease-in-out;
	max-height: 100%;
}

@keyframes lineanimation2 {
	0% { max-height: 0 }
	50.5% { max-height: 0 }
	51% { max-height: 100% }
	100% { max-height: 100% }
}

.vbcbg_lines{
	width: 100%;
	-height: 1px;
	border-bottom: dashed 1px #C8CBCF;
	position: absolute;
	color: #C8CBCF;
	text-align: right;
	height: 0;
}

.vbcbgl-2{
	top: 0%
}
.vbcbgl-3{
	top: 25%
}
.vbcbgl-4{
	top: 50%
}
.vbcbgl-5{
	top: 75%
}

.vbcbgln-2{
	top: 0%
}
.vbcbgln-3{
	top: 25%
}
.vbcbgln-4{
	top: 50%
}
.vbcbgln-5{
	top: 75%
}

.sankey-dots {
	animation: sankey-animation 2s infinite;
	animation-timing-function: linear;
}

@keyframes sankey-animation {
	0% {
		clip-path: inset(-25% 100% 0% -20%);
	}

	100% {
		clip-path: inset(-25% -30% 0% 100%);
	}
}

.calendar-icon-dark {
	display: none;
}
.dark .calendar-icon-dark {
	display: initial;
}
.dark .calendar-icon {
	display: none;
}


.text-2xl{
	font-size: 1.4vw;
}

.text-1xl{
	font-size: 1.2vw;
}

.text-3xxl {
	font-size: 1.6vw;
}

.text-3xl{
	font-size: 1.5vw;
}

.text-5xl{
	font-size: 2.5vw;
	line-height: 3vw;
}

.text-6xl{
	font-size: 3.5vw;
}

.netzero__kpi{
	margin-top: 4vw;
}

.netzero__kpi--card{
	
}

.netzero__kpi--left div{
	width: 1vw;
	height: 1vw;
	border-radius: 500px;
	background-color: red;
}

.netzero__kpi--left .up{
	border-radius: 0;
	background-color: transparent;
	width: 0;
	height: 0;
	border-left: .7vw solid transparent;
	border-right: .7vw solid transparent;
	border-bottom: .7vw solid rgb(25, 172, 86);
	margin-top: .5vw;
}

.netzero__kpi--left .down{
	border-radius: 0;
	background-color: transparent;
	width: 0;
	height: 0;
	border-left: .7vw solid transparent;
	border-right: .7vw solid transparent;
	border-top: .7vw solid rgb(172, 33, 33);
	margin-top: .5vw;
}

.invertDownUp .up{
	border-bottom: .7vw solid rgb(172, 33, 33);
}

.invertDownUp .down{
	border-top: .7vw solid rgb(25, 172, 86);
}

.netzero__kpi--badge{
	font-size: .8vw;
	font-weight: 400;
	background-color: #aa3333;
	border-radius: .25rem;
	padding: .2vw .4vw .3vw .4vw;
	display: flex;
	align-items: center;
	margin-left: 1rem;
	margin-top: auto;
}

.hole {
	width: 5vw;
	height: 5vw;
	border-radius: 50%;
	background: #1A1F25;
	display: flex;
	align-items: center;
	justify-content: center;
}

.donut {
	width: 8vw;
	height: 8vw;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: conic-gradient(#5D9A79 0deg var(--angle), #B96A36 var(--angle) 360deg)
}

.nodata{
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
	flex-direction: column;
}

.nodata h2{
	font-size: 2vw;
}

.slidermenu {
	position: fixed;
	bottom: 2vw;
	right: 2vw;
	text-align: center;
	opacity: 0;
	transition: .5s;
}

.slidermenu:hover{
	opacity: 1;
}

.-slidermenu a {
	background-color: #323745;
	color: white;
	font-size: 2.4vw;
	display: inline-block;
	width: 4vw;
	height: 4vw;
	margin: .2vw;
	border-radius: .3vw;
	text-align: center;
	cursor: pointer;
	box-shadow: 0px 0px 60px #00000060;
}

.animationsPaused .buildingCharts{
	animation-play-state: paused;
}

.buildingCharts--mainWrapper{
	position: absolute;
	left: 20%;
	top: 20px;
	height: 96vh;
	width: 79%;
	overflow: hidden;
}

.buildingChart{
	position: initial;
	height: 96vh;
	padding: 3vh 3%;
	overflow: hidden;
}

.buildingCharts {
	position: relative;
	-animation: slider1pages var(--duration) infinite;
	top: 100%;
	top: 0;
	transition: 1s;
}

.energyProduction__categories{
	display: flex;
	width: 100%;
	color: #C8CBCF;
	flex-direction: row;
	justify-content: space-between;
	font-size: .8rem;
}

.customml-1{
	margin-left: 1vw;
}

.solarInstalation {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transform: scale(1.1);
}

.text-production{
	color: #3fb68e !important;
}

.bg-production{
	background: #3fb68e !important;
}

.text-consumption{
	color: #ef8d5d !important;
}

.bg-consumption{
	background: #ef8d5d !important;
}

.text-sold{
	color: #74abe2 !important;
}

.bg-sold{
	background: #74abe2 !important;
}

.error__message{
	background-color: #080912;
    width: 100%;
    height: 100%;
    position: fixed;
	padding: 20vh 20% 0 20%;
}

.error__message p{
	color: #fff;
    font-size: 2em;
}

.error__message--icon{
	text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}

.error__trace{
	padding: 1em;
    background: #00000050;
    border: solid 1px #3e3a51;
    font-size: .8em;
	margin-top: 2em;
}


.columnChart{
	display: flex;
	flex-direction: row;
	padding: 0.5vh 0 1.5vh 0;
}

.columnChart--selected{
	background-color: #00000050;
	background-color: #ffffff10;
	border-radius: 1em;
}

.columnChart__header{
	width: 20%;
}

.columnChart__body {
	width: 70%;
	margin-left: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.columnChart__header--title{

}

.columChartLine {
	width: 100%;
	height: 10px;
	background: #C8CBCF;
	margin-bottom: 5px;

	border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: rgb(93, 154, 121);
}

.columChartLine--compare {
	background-color: rgb(46, 68, 62);
}

.slogan__animation{
	height: 4.5vh;
	overflow: hidden;
}

.slogan__animation div{
	position: relative;
}

.slogan__animation div .slogan__animation__logo{
	position: relative;
	animation: sloganAnimation1 infinite 10s linear;
}

.slogan__animation div .slogan__animation__slogan{
	position: relative;
	animation: sloganAnimation2 infinite 10s linear;
}

@keyframes sloganAnimation1 {
	0% { top: 0 }
	45% { top: 0; opacity: 1; }
	50% { top: -4.5vh; opacity: 0 }
	95% { top: 4.5vh; opacity: 0 }
	100% { top: 0vh; opacity: 1; }
}

@keyframes sloganAnimation2 {
	0% { top: 0 }
	45% { top: 0 }
	50% { top: -4.5vh }
	95% { top: -4.5vh }
	100% { top: -9vh }
}